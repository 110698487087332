@import (less) "../../../build/assets/iconfont/icon";

// CONTAO
.invisible, #contao-debug {
	display: none !important;
}

.image_container {
	
	&.float_left {
		float: left;
		margin: 5px 20px 10px 0;
	}
	
	&.float_right {
		float: right;
		margin: 5px 0 10px 20px;
	}
	
	a {
		display: block;
	}
	
	.caption {
		line-height: 160%;
		padding: 0px 5px;
		font-size: 13px;
	}
}

.ce_text {
	
	h1,h2,h3,h4,h5,h6 {
		
		width: 100%;
	}
}



// CONTAO END

body {
	//height: 100%;
	//padding-bottom: 60px; // = height footer
	min-width: 725px; // todo: remove -> responsive
}


.view {
	min-height: 100%;
	position: relative;
}

.page {
	padding-bottom: 60px; // = height footer
}

.footer {
	position:absolute;
	bottom: 0px;
	width: 100%;
	height: 60px;
}

a, a:hover, a:active, a:focus {
	color: #ff9300;
}

a:hover {
	//text-decoration: underline;
	color: #FFA913;
}

h1,h2,h3,h4,h5,h6 {
	color: #000;
}

h1,h2 {
	font-size: 36px;
	display: inline-block;
	line-height: 130%;
	
	a {
		color: #000 !important;
		text-decoration: none !important;
	}
}

.icon {
	text-indent: -9999em;
	
	i {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		//display: table;
		//table-layout: fixed;
		text-indent: 0;
		color: #fff;
		font-size: 16px;
		line-height: 26px;
		text-align: center;

		&:before {
			//display: table-cell;
			//text-align: center;
			//vertical-align: middle;
		}

		&.icon-twitter {
			background-color: #3baae1;
		}

		&.icon-linkedin {
			background-color: #0085af;
		}

		&.icon-xing {
			background-color: #005d5e;
			
			&:before {
				padding-top: 5px;
			}
		}
	}
}

.main {
	margin-top: 50px;
}

.footer {
	text-align: center;

	a {
		font-size: 13px;
		line-height: 60px;
		color: #fff;
	}
}


.mod_aawTile_list { // DEBUG
	color: #fff;
}



.bg-black {
	color: #fff;
}

.bg-grey {
	background: #edecec;
}

.kk-content {
	margin: 0px;
	padding: 30px 30px 20px 30px;
}

.aside-right.bg-black {
	margin-left: 10px;
	padding-top: 0;
	padding-bottom: 0;
	padding-right: 0;
}

// NAV

.main {

	.mod_navigation {
		margin-bottom: 30px;

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		li {
			margin: 0;
			padding: 0;

			&:first-child {
				
				a, span {
					border-top: 1px solid #636363;
				}
			}
		}

		span, a {
			line-height: 38px;
			border-bottom: 1px solid #636363;
			display: block;
			padding: 0 0 0 38px;
			text-transform: uppercase;
			font-size: 12px;
		}
		
		a {
			-webkit-transition: color 0.3s;
    		transition: color 0.3s;
		}
		
		a:hover {
			color: #FFA913;
		}

		span {
			position: relative;
			
			.icon-caret-left;
			
			&:before {
				position: absolute;
				left: 6px;
				top: 0px;
				font-size: 17px;
			}

			/*&:after {
				content: '';
				position: absolute;
				width: 12px;
				height: 12px;
				top: 50%;
				margin-top: -6px;
				left: 0;
				background-image: url(../../images/navigation-arrow-left.png);
			}*/
		}

		a {
			color: #fff;
		}
	}
}

.mod_newslist {
	margin: -30px -30px -20px -30px;
	
	.empty {
		padding: 30px 20px;
	}

	.layout_latest {
		border-bottom: 10px solid #000;
		padding: 30px 30px 20px 30px;
		position: relative;
	}

	.archivename {
		width: 32%;
		height: 100%;
		float: left;
		margin: -30px 0 -20px -30px;	
		position: absolute;
		overflow: hidden;
	}

	.archivetitle {
		position: absolute;
		width: 100%;
		height: 50%;
		left: 0;
		top: 0;
		box-sizing: border-box;
		//border-bottom: 5px solid #EDECEC;
		padding: 20px;
		
		
		span {
			font-size: 32px;
			color: #fff;
			line-height: 120%;
		}
	}

	.archiveimage {
		position: absolute;
		width: 100%;
		height: 50%;
		left: 0;
		bottom: 0;
		box-sizing: border-box;
		//border-top: 5px solid #EDECEC;
		background-repeat: no-repeat;
		background-position: center center;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
	}

	.archivecontent {
		width: 65%;
		float: right;
	}

	time {
		font-style: italic;
	}

	.more {
		float: right;
		margin: 0;
		font-weight: bold;
		/*background-image: url(../../images/more-arrow-right.png);
		background-position: 0 2px;
		background-repeat: no-repeat;*/
		padding-left: 20px;
		
		position: relative;
		.icon-caret-right;
		
		&:before {
			position: absolute;
			left: 0;
			top: 0;
			font-size: 20px;
		}
	}
	
	.pagination {
		overflow: hidden;
		background: #000;
		padding: 0 30px;
		
		p {
			display: block;
			float: left;
			color: #EDECEC;
		}
		
		ul {
			display: block;
			float: right;
			list-style: none;
			
			li {
				display: inline-block;
				
				span {
					color: #EDECEC;
				}
			}
		}
	}
}

.aside-right {
	
	.mod_navigation {
		
		ul {
			margin-left: -30px;
		}
		
		a, span {
			padding: 0px 0px 0px 30px;
		}
	}
	
	.mod_navigation.blog-backlink {
		margin-bottom: 0;
		
		li a {
			border-bottom: none;
		}
	}
	
	h1,h2,h3,h4,h5,h6 {
		color: #F90 !important;
		font-size: 15px;
		text-transform: uppercase;
		
		a {
			color: #F90 !important;
			font-size: 15px;
			text-transform: uppercase;
		}
	}
	
	.mod_newslist {
		margin: 0 0 30px 0;
		
		h1,h2,h3,h4,h5,h6 {
			margin-bottom: 10px;
		}
		
		.layout_short {
			margin-bottom: 7px;
			
			.info {
				display: none;
			}
			
			h2 {
				margin: 0;
				font-size: 13px;
				color: #fff !important;
				
				a {
					font-size: 13px;
					color: #fff !important;
					text-transform: none;
					-webkit-transition: color 0.2s ease 0s;
					transition: color 0.2s ease 0s;
					
					&:hover {
						color: #FFA913 !important;
					}
				}
			}
			
			.teaser {
				display: none;
			}
			
			.more {
				display: none;
			}
		}
	}
}


.mod_newsreader {
	
	.layout_full {
		position: relative;
	}

	.archivename {
		position: relative;
		margin: -30px -30px 30px -30px;
		height: 165px;
	}

	.archivetitle {
		width: 34%;
		left: 0;
		top: 0;
		height: 100%;
		position: absolute;
		box-sizing: border-box;
		//border-right: 5px solid #EDECEC;
		padding: 20px;
		
		span {
			font-size: 32px;
			color: #fff;
			line-height: 120%;
		}
	}

	.archiveimage {
		width: 66%;
		right: 0;
		top: 0;
		height: 100%;
		position: absolute;
		box-sizing: border-box;
		//border-left: 5px solid #EDECEC;
		background-repeat: no-repeat;
		background-position: center center;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
	}

	time {
		font-style: italic;
	}
}

.archivename {

	.archivetitle {
		background: #ea9700;
	}
}

.ce_form.tableless {
	
	label {
		display: block;
		line-height: 35px;
	}
	
	input {
		height: 35px;
		padding: 0px 10px;
		box-sizing: border-box;
		border: 1px solid #fff;
		min-width: 50%;
	}
	
	textarea {
		box-sizing: border-box;
		border: 1px solid #fff;
		line-height: 150%;
		min-width: 75%;
		width: auto;
	}
	
	select {
		box-sizing: border-box;
		border: 1px solid #fff;
		padding: 0px 10px;
		height: 35px;
		min-width: 25%;
	}
	
	.widget {
		margin-bottom: 10px;
	}
	
	.captcha_text {
		line-height: 35px;
		display: block;
	}
	
	.submit {
		background: #ff9300;
		border: none;
		text-shadow: none;
		margin-top: 30px;
		min-width: auto;
		padding: 10px 15px;
		
		&:hover {
			background: #FFA913;
		}
	}
}


@media screen and (max-width: 800px) {
	
	.aside-right.bg-black {
		margin: 30px 0 0 0;
		padding: 0;
		
		.mod_navigation ul {
			margin: 0;
		}
	}
	
	.main-blog {

		.kk-container {
			position: relative;
		}
		
		.mod_navigation {
			position: absolute;
			top: 0;
			width: 100%;
		}
		
		.kk-column-75 .kk-content {
			margin-top: 304px;
		}
		
		.blog-backlink {
			display: none;
		}
		
		.aside-right.bg-black {
			margin-top: 30px;
			
			.mod_newslist {
				float: left;
				width: 50%;
			}
			
			.social {
				float: right;
				width: 50%;
			}
		}
		
		.mod_newslist .pagination {
			padding: 0;
			
			p, ul {
				margin: 0;
				padding: 0;
			}
		}
	}
	
	
	
	/*
	.main {
		margin-bottom: 300px;
		
		.kk-column-75 {
			position: relative;
			top: 300px;
		}
		
		.kk-column-25 {
			position: relative;
			top: 300px;
		}
		
		.mod_navigation {
			position: absolute;
			top: -573px;
			width: 100%;
		}
	}
	
	.aside-right.bg-black {
		margin: 0;
		padding: 0;
		
		.mod_navigation ul {
			margin: 0;
		}
		
		.mod_newslist {
			float: left;
			width: 50%;
		}
		
		.social {
			float: right;
			width: 50%;
		}
	}
	*/
}